import type { FreshCheckoutQuoteReturn } from './types';
import { useStatelessFreshQuote } from '../useStatelessFreshQuote';
import { useMemo } from 'react';
import { getHasSalesTax } from '../../utils/quote';
import { SubscriptionType } from '@farmersdog/lead-browser-storage';
import sumBy from 'lodash/sumBy';
import { BoxProgressionStrategy } from 'src/graphql/tosa.types';
import { isHandledQuoteTaxError } from '../useStatelessFreshQuote/utils/isHandledQuoteTaxError';
import { Logger } from '@farmersdog/logger';
import { doesTrialAndRegularAmountsMatch } from './doesTrialAndRegularAmountsMatch';

function getCentsFromDollars(dollars: number): number {
  return Math.round(dollars * 100);
}

export function useFreshCheckoutQuote(): FreshCheckoutQuoteReturn {
  const { quote, error } = useStatelessFreshQuote();
  const hasTaxFetchingError = isHandledQuoteTaxError(error);

  return useMemo(() => {
    const log = new Logger('app:pages:Signup:useFreshCheckoutQuote');
    if (!quote) {
      return {
        hasQuote: false,
        hasSalesTaxApplied: false,
        totalAmount: 0,
        hasDiscount: false,
        subtotalAmount: 0,
        taxAmount: 0,
        trialDiscountPercentage: 0,
        regularDiscountPercentage: 0,
        regularPricePerWeek: 0,
        regularDailyPrice: 0,
        trialDailyPrice: 0,
        totalPrice: 0,
        subscribeAndSaveOrderSubtotal: 0,
        subscribeAndSaveDiscount: 0,
        orderDiscountDollarAmount: 0,
        treatsDiscountDollarAmount: 0,
        pets: [],
        trialOrderDaysOfFood: 0,
        regularOrderDaysOfFood: 0,
        trialOrderTreatLineItems: [],
        trialOrderTreatsTotal: 0,
        type: SubscriptionType.Fresh,
        boxProgressionStrategy: BoxProgressionStrategy.Legacy,
        householdCalories: 0,
      };
    }

    const totalPrice =
      getCentsFromDollars(quote.regularOrder.breakdown.daily.amount) *
      quote.trialOrder.planDetails.daysOfFood;
    const trialTotalPrice =
      getCentsFromDollars(quote.trialOrder.breakdown.daily.amount) *
      quote.trialOrder.planDetails.daysOfFood;
    const trialDiscountPercentage =
      quote.trialOrder.discount.discountPercentage;

    // Pet-specific pricing
    const trialOrderQuotePets = quote.trialOrder.breakdown.daily.pets;
    const regularOrderQuotePets = quote.regularOrder.breakdown.daily.pets;

    const isMultiPet = regularOrderQuotePets.length > 1;
    const petsPrices = regularOrderQuotePets.map(
      (regularOrderPriceForPet, i) => {
        const trialOrderPriceForPet = trialOrderQuotePets[i];

        const regularDailyPrice = getCentsFromDollars(
          regularOrderPriceForPet.amount
        );
        const trialDailyPrice = getCentsFromDollars(
          trialOrderPriceForPet.amount
        );

        return {
          name: regularOrderPriceForPet.name,
          subtotal: isMultiPet
            ? regularDailyPrice * quote.trialOrder.planDetails.daysOfFood
            : totalPrice,
          trialSubtotal: isMultiPet
            ? trialDailyPrice * quote.trialOrder.planDetails.daysOfFood
            : trialTotalPrice,
          regularDailyPrice,
          trialDailyPrice,
        };
      }
    );

    const trialOrderTreatsTotal = sumBy(
      quote.trialOrder.breakdown.lineItems.treats,
      'discountedAmount'
    );

    const householdCalories = quote.temporaryPetPlans.reduce(
      (total, plan) => total + plan.dailyKCal,
      0
    );

    if (
      quote.boxProgressionStrategy === BoxProgressionStrategy.Legacy &&
      !doesTrialAndRegularAmountsMatch(
        quote.trialOrder.breakdown.daily,
        quote.regularOrder.breakdown.daily
      )
    ) {
      log.warn('Trial and Regular daily amounts are not equal', {
        trialDailyBreakdown: quote.trialOrder.breakdown.daily,
        regularDailyBreakdown: quote.regularOrder.breakdown.daily,
      });
    }

    return {
      // Quote Info
      hasQuote: !!quote,
      // TODO: We need to align on communicating sales tax calculated as 0 just for the trial vs
      // not having sales tax applied at all vs sales tax not being calculated yet.
      // https://app.shortcut.com/farmersdog/story/126061
      hasSalesTaxApplied: hasTaxFetchingError
        ? false
        : getHasSalesTax(quote.trialOrder.tax),
      // Pricing
      totalAmount: getCentsFromDollars(quote.trialOrder.grandTotal),
      hasDiscount: trialDiscountPercentage > 0,
      subtotalAmount: getCentsFromDollars(
        quote.trialOrder.preTaxDiscountedSubtotal
      ),
      taxAmount: getCentsFromDollars(quote.trialOrder.tax ?? 0),
      trialDiscountPercentage,
      regularDiscountPercentage: quote.regularOrder.discount.discountPercentage,
      regularPricePerWeek: getCentsFromDollars(
        quote.regularOrder.breakdown.weekly.amount
      ),
      regularDailyPrice: getCentsFromDollars(
        quote.regularOrder.breakdown.daily.amount
      ),
      trialDailyPrice: getCentsFromDollars(
        quote.trialOrder.breakdown.daily.amount
      ),
      totalPrice,
      // Subscribe and save
      subscribeAndSaveOrderSubtotal:
        quote.subscribeAndSaveQuote?.subscribeAndSaveOrderSubtotal,
      subscribeAndSaveDiscount:
        quote.subscribeAndSaveQuote?.subscribeAndSaveDiscount,
      orderDiscountDollarAmount:
        quote.subscribeAndSaveQuote?.orderDiscountDollarAmount,
      treatsDiscountDollarAmount:
        quote.subscribeAndSaveQuote?.treatsDiscountDollarAmount,
      // Pet-specific pricing
      pets: petsPrices,
      // Shipment
      trialOrderDaysOfFood: quote.trialOrder.planDetails.daysOfFood,
      regularOrderDaysOfFood: quote.regularOrder.planDetails.daysOfFood,
      // treats
      trialOrderTreatLineItems: quote.trialOrder.breakdown.lineItems.treats,
      trialOrderTreatsTotal: getCentsFromDollars(trialOrderTreatsTotal),
      type: SubscriptionType.Fresh,
      boxProgressionStrategy:
        quote.boxProgressionStrategy ?? BoxProgressionStrategy.Legacy,

      // Used for surprise higher discount
      householdCalories,
    };
  }, [quote, hasTaxFetchingError]);
}
