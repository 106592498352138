import { useCallback, useEffect, useRef, useState } from 'react';

export function useSetTimeout(fn: () => void, timeout: number) {
  const [isFunctionQueued, setIsFunctionQueued] = useState(false);

  const timeoutId = useRef<NodeJS.Timeout>();

  const cancelFunction = useCallback(() => {
    setIsFunctionQueued(false);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  }, []);

  useEffect(() => {
    // This cleans up the timeout if the hook unmounts before it's had a chance
    // to run
    return cancelFunction;
  }, [cancelFunction]);

  const runFunction = useCallback(() => {
    setIsFunctionQueued(false);
    fn();
  }, [fn]);

  const queueFunction = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    setIsFunctionQueued(true);
    timeoutId.current = setTimeout(runFunction, timeout);
  }, [runFunction, timeout]);

  return { queueFunction, cancelFunction, isFunctionQueued };
}
