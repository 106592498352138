import { useCallback, useMemo, useRef } from 'react';

import { NodeNames } from '@farmersdog/constants';

import { getNodeNameAndPosition } from '../../../blueprint/utils';
import { useSignupUpdatedAssets } from '../../../hooks/experiments';
import { useSignupCardSelectors } from '../../../hooks/experiments/useSignupCardsSelectors';
import { useLabel } from '../../../hooks/useLabel';
import { useLingerAndSubmitFormById } from '../../../hooks/useLingerAndSubmitFormById';
import { SubmitButton } from '../../shared';
import { RadioSelectorGroup } from '../base';
import { CardRadioGroup } from '../base/CardRadioGroup';
import { getIconAltTag } from '../base/RadioSelectorGroup/utils';

import styles from './ActivityLevelInput.module.css';
import {
  activityLevelAssets,
  ActivityLevelAssets,
  activityLevelNewAssets,
} from './assets';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';
import type { SelectionOption, Value } from '../base/CardRadioGroup';

export function ActivityLevelInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods, useFeature, formSubmitRequest, parentFormId } =
    props;
  const { getValues, register } = formMethods;
  const { position } = getNodeNameAndPosition(node.name);

  const registerProps = register(node.name);
  const activityLevelField = `${NodeNames.ActivityLevel}-${position}` as const;
  const currentValue = getValues(activityLevelField) ?? null;
  const hasInitialValue = useRef(!!currentValue);
  const { frontLabel } = useLabel({ node, getValues });

  const radioGroupOptions =
    node.input?.options?.map(({ name, value, description }) => ({
      label: name,
      value,
      description,
    })) ?? [];

  const { isSignupUpdatedAssetsOn } = useSignupUpdatedAssets({
    useFeature,
  });

  const imageAssets = isSignupUpdatedAssetsOn
    ? activityLevelNewAssets
    : activityLevelAssets;

  const cardGroupOptions: SelectionOption<Value>[] = useMemo(() => {
    return (
      node.input?.options?.map(({ name, value, description }, index) => ({
        heading: name,
        value,
        description: `${description}.`,
        imageSources: imageAssets.large[index],
        imageAlt: getIconAltTag(value),
      })) ?? []
    );
  }, [node.input?.options, imageAssets.large]);

  const { shouldShowSignupCardSelectors, shouldIncludeContinueBtn } =
    useSignupCardSelectors({
      useFeature,
    });

  const { queueSubmitForm, isFormSubmissionQueued } =
    useLingerAndSubmitFormById(parentFormId);

  const cardGroupOnChange = useCallback(
    async (value: Value) => {
      const event = {
        target: {
          name: node.name,
          value,
        },
      };

      await registerProps.onChange(event);

      if (!shouldIncludeContinueBtn && !hasInitialValue.current) {
        queueSubmitForm();
      }
    },
    [node.name, registerProps, shouldIncludeContinueBtn, queueSubmitForm]
  );

  return (
    <>
      <span>{frontLabel}</span>

      {shouldShowSignupCardSelectors ? (
        <div className={styles.wrapper}>
          <CardRadioGroup
            options={cardGroupOptions}
            name={registerProps.name}
            currentValue={currentValue}
            onChange={cardGroupOnChange}
            groupDescription="Select the activity level closest to your dog's."
          />
        </div>
      ) : (
        <RadioSelectorGroup
          registerProps={registerProps}
          options={radioGroupOptions}
          icons={imageAssets}
          defaultMobileIconIndex={ActivityLevelAssets.Active}
          legend="Activity Level"
          currentValue={currentValue}
          defaultDescription="Select the activity level closest to your dog's."
        />
      )}

      {currentValue && !isFormSubmissionQueued && (
        <SubmitButton loading={formSubmitRequest.loading} {...props} />
      )}
    </>
  );
}
